import React from 'react';
import {Helmet} from 'react-helmet';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import './layout.scss'

const Layout = ({pageTitle, pageDesc, pageLang, pageMeta, children}) => {
  const query = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            description
            title
            author
          }
        }
      }
    `
  )

  const site = query.site.siteMetadata
  
  const description = pageDesc || site.description
  const title = pageTitle ? `${pageTitle} | ${site.title}` : site.title
  const meta = pageMeta || []

  return ( 
    <div className="d-flex flex-column min-vh-100">
      <Helmet
        defaultTitle={site.title}
        title={title}
        meta={[
          {
            name: 'description',
            content: description
          },
          {
            name: 'author',
            content: site.author
          },
          {
            property: 'og:title',
            content: title
          },
          {
            property: 'og:locale',
            content: pageLang
          },
          {
            property: 'og:description',
            content: description
          },
          {
            property: 'og:site_name',
            content: site.title
          },
          {
            property: 'twitter:title',
            content: site.title
          },
          {
            name: "twitter:card",
            content: "summary"
          },
          {
            name: "twitter:site",
            content: "@"
          },
          {
            name: "twitter:creator",
            content: `@${site.author}`
          },
          {
            name: "msapplication-TileColor",
            content: "#00e000"
          },
          {
            name: "msapplication-config",
            content: "https://assets.tubs.wtf/img/favicon/browserconfig.xml"
          },
          {
            name: "theme-color",
            content: "#00e000"
          }
        ].concat(meta)}
        link={[
          {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "https://assets.tubs.wtf/img/favicon/apple-touch-icon.png"
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: "https://assets.tubs.wtf/img/favicon/favicon-32x32.png"
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: "https://assets.tubs.wtf/img/favicon/favicon-16x16.png"
          },
          {
            rel: "manifest",
            href: "https://assets.tubs.wtf/img/favicon/site.webmanifest"
          },
          {
            rel: "mask-icon",
            href: "https://assets.tubs.wtf/img/favicon/safari-pinned-tab.svg",
            color: "#00e000"
          },
          {
            rel: "shortcut icon",
            href: "https://assets.tubs.wtf/img/favicon/favicon.ico"
          }
        ]}
      >
        <html lang={pageLang}/>
        <noscript>
          {"<link rel=\"stylesheet\" href='https://assets.tubs.wtf/css/noscript-hide.css'/>"}
        </noscript>
        <script type="application/ld+json">{`
          {
            "@type": "WebSite",
            "publisher": {
              "@type": "Organization",
              "logo": {
                "@type": "ImageObject",
                "url": "https://assets.tubs.wtf/img/favicon/android-chrome-512x512.png"
              },
              "name": "TUBS"
            },
            "name": "${title}",
            "author": {
              "@type": "Person",
              "name": "${site.author}"
            },
            "headline": "${title}",
            "description": "${description}",
            "@context": "https://schema.org"
          }
        `}</script>
      </Helmet>
      <Navigation/>
      <main role="main" className="flex-fill my-4">
        <div className="container mb-5">
          <h1>{pageTitle}</h1>
          {children}
        </div>
      </main>
      <Footer/>
    </div>
  );
}
 
export default Layout;

function Navigation() {
  const navItems = [
    {
      text: "Members",
      href: "https://tubs.wtf/members",
    },
    {
      text: "Tools",
      href: "/",
    },
    {
      text: "ASSSS",
      href: "https://assss.tubs.wtf",
    },
    {
      text: "Translations",
      href: "https://tl.tubs.wtf",
    },
    {
      text: "Lord Bathbomb",
      href: "https://tubs.wtf/lord-bathbomb",
    },
    {
      text: "Other Projects",
      href: "https://tubs.wtf/projects",
    },
  ]
  return (
    <header className='mb-5'>
      <Navbar bg="primary" variant="dark" expand="md" fixed="top" className="px-3">
        <Navbar.Brand href="https://tubs.wtf">TUBS</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar"/>
        <Navbar.Collapse id="navbar">
          <Nav>
            {navItems.map((item) => {
              if (item.text === "Tools") {
                return <Nav.Link as={Link} to={item.href} className="active">{item.text}</Nav.Link>
              } else {
                return <Nav.Link href={item.href}>{item.text}</Nav.Link>
              }
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

const Footer = () => {
  return (
    <footer className="footer mt-auto pt-3 pb-1 bg-primary text-white">
      <p className="mx-3">© 2020 - 2022 TUBS</p>
    </footer>
  );
}